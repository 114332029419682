import * as creationStatusUtil from '@kritik/utils/creation/status';
import * as ActivityStatusUtil from '@kritik/utils/stage';
import { getAssignmentSubmissions } from 'actions/activity';
import { getStudentsByCourse } from 'actions/students';
import ActivitySelector from 'components/ActivityEdit/Calibration/ActivitySelector';
import CalibrationCreationEditor from 'components/ActivityEdit/Calibration/CalibrationCreationEditor';
import InstructionsField from 'components/ActivityEdit/Details/Fields/Instructions';
import { FIELD_NAME as INSTRUCTIONS } from 'components/ActivityEdit/Details/Fields/Instructions/constants';
import ObjectiveField from 'components/ActivityEdit/Details/Fields/Objective';
import { FIELD_NAME as OBJECTIVE } from 'components/ActivityEdit/Details/Fields/Objective/constants';
import TitleField from 'components/ActivityEdit/Details/Fields/Title';
import { FIELD_NAME as RUBRIC, FIELD_LABEL as RUBRIC_LABEL } from 'components/ActivityEdit/Details/Rubric/constants';
import RubricPreview from 'components/ActivityEdit/Rubric/Preview';
import Button from 'components/buttons/Button';
import ButtonContainer from 'components/buttons/ButtonContainer';
import FormContainer from 'components/core/form/Container';
import FormField from 'components/core/form/Field';
import FormFieldLabel from 'components/core/form/FieldLabel';
import FormSection from 'components/core/form/Section';
import Select from 'components/core/form/SelectField';
import FormTitle from 'components/core/form/Title';
import AttachmentManager from 'components/layout/AttachmentManager';
import AvatarDisplay from 'components/layout/AvatarDisplay';
import InlineInformation from 'components/layout/InlineInformation';
import PageContainer from 'components/layout/PageContainer';
import PageHeader from 'components/layout/PageHeader';
import Badge from 'images/icons/svg/BadgeLamp';
import React from 'react';
import { Field as FieldRFF, FormSpy } from 'react-final-form';
import { connect } from 'react-redux';
import {
  getCalibrationActivity,
  getCalibrationCourses,
  getFinalizedActivitiesWithoutCalibrations,
  selectCalibrationActivityCourseList,
  selectCalibrationCreationEditorVisibility,
  selectCalibrationOptions,
  setCalibrationCreationEditorVisibility,
  setCalibrationCreationOptions,
  showCreationSelector,
} from 'redux/activityEdit';
import creationSelector from 'selectors/creation';
import { getNewKS } from 'utils/student';

import { hasCriteriaOrLevelChanged } from '@kritik/utils/rubric';
import { resetAsync } from 'actions/async';
import localUtil from 'components/ActivityEdit/util';
import { TranslatedText } from 'components/TranslatedText';
import FullScreenModal from 'components/modals/FullScreenModal';
import Banner from 'components/tutorial/Banner';
import { localize } from 'locales';
import { CREATE_CALIBRATION_CREATION, UPDATE_CALIBRATION_CREATION } from 'redux/activityEdit/types';
import { selectCurrentActivity } from 'selectors/activity';
import { selectCurrentCourse } from 'selectors/course';
import { formatPartialCourseBreadcrumb } from 'utils/format';
import CalibrationSourceOption from './CalibrationSource';
import utils from './utils';

const validateCalibrationCreations = (creationList: any, formValues: any) => {
  const {
    settings: { isEdit, activity },
  } = formValues;
  if (isEdit && ActivityStatusUtil.isScheduled(activity)) {
    return creationList.includes(null) || creationList.length < 3 ? 'All creations must be selected' : undefined;
  }
  return undefined;
};

function SelectedCalibrationCreation({
  creation,
  activity,
  selectedActivity,
  user,
  creationIndex,
  handleRemoveCreation,
  handleSelectCreation,
  testid,
  creationFormValue,
  isDuplicating,
  ...props
}: any) {
  const text = {
    btnCreateACreation: localize({ message: 'Calibration.Form.Button.CreateCreation' }),
    btnSelectCreation: localize({ message: 'Calibration.Form.Button.SelectCreation' }),
    btnEdit: localize({ message: 'Calibration.Form.Button.Edit' }),
    btnAnother: localize({ message: 'Calibration.Form.Button.Another' }),
  };

  const handleClickCreateCreation = () => {
    props.setCreationIndex(creationIndex);
    props.setCalibrationCreationEditorVisibility(true);
  };

  const evalStarted = activity ? ActivityStatusUtil.isEvaluateOrLater(activity) && !isDuplicating : false;

  const renderUserName = (userName: any) => {
    return <p style={{ marginLeft: '10px' }}>{userName}</p>;
  };

  const getUserName = (isCalibrationCreation: any) => {
    let content;
    if (isCalibrationCreation) {
      content = (
        <React.Fragment>
          <div>Your Creation</div>
          <div>Will appear as an anonymous student</div>
        </React.Fragment>
      );
    } else {
      content = creation.group ? creation.group.name : creation.user.profile.name;
    }

    return <div>{content}</div>;
  };

  const hasAvailableCreations = () => {
    return props.calibrationCreationOptions && props.calibrationCreationOptions.length > 0;
  };
  const renderSelectCreationButton = () => {
    if (!hasAvailableCreations()) {
      return null;
    }
    return (
      <Button
        type="secondary"
        onClick={() => {
          return handleSelectCreation(creationIndex);
        }}
        data-testid="select-creation"
      >
        {text.btnSelectCreation}
      </Button>
    );
  };
  const renderChangeCreationButton = () => {
    if (!hasAvailableCreations()) {
      return null;
    }
    return (
      <Button
        type="secondary"
        onClick={() => {
          return handleSelectCreation(creationIndex);
        }}
        data-testid="change-creation"
        label={localize({ message: 'Button.Label.ChangeCalibrationCreation' })}
      >
        {text.btnAnother}
      </Button>
    );
  };

  let creationInfo = null;
  if (creation && creation.user) {
    const isCalibrationCreation = creation.calibrationCreation;
    const userName = getUserName(isCalibrationCreation);
    const isEmptyMarks = creationFormValue ? creationFormValue.marks?.length === 0 : false;
    creationInfo = (
      <div className="calibration-edit-creations__item-outline">
        <div className="calibration-edit-creations__item-info">
          <div className="calibration-edit-creations__student-info">
            <AvatarDisplay
              user={isCalibrationCreation ? user : creation.user}
              kritikScore={isCalibrationCreation ? 5000 : getNewKS(creation.student)}
              groupCard={selectedActivity && selectedActivity.isGroupActivity}
              group={creation.group}
              size="50px"
            />
            {renderUserName(userName)}
          </div>
          {!evalStarted && (
            <div className="calibration-edit-creations__menu">
              <ButtonContainer>
                {isCalibrationCreation && (
                  <Button
                    type="secondary"
                    onClick={() => {
                      props.setCalibrationCreationEditorVisibility(true);
                      props.setCreationIndex(creationIndex);
                    }}
                    data-testid="edit-calibration-creation"
                    label={localize({
                      message: 'Button.Label.EditCreation',
                      options: { creationType: 'calibration' },
                    })}
                  >
                    {text.btnEdit}
                  </Button>
                )}
                {renderChangeCreationButton()}
                <Button
                  type="hidden"
                  className="calibration-edit-creations__menu-remove"
                  onClick={() => {
                    return handleRemoveCreation(creationIndex);
                  }}
                  label={localize({ message: 'Button.Label.RemoveCalibrationCreation' })}
                >
                  <i aria-hidden="true" className="fas fa-times" />
                </Button>
              </ButtonContainer>
            </div>
          )}
        </div>
        {isEmptyMarks && (
          <div data-testid="empty-marks-warning">
            <InlineInformation type="danger">
              {localize({
                message: 'Rubric.Edit.CalibrationCreation.NotGradedWarning',
              })}
            </InlineInformation>
          </div>
        )}
      </div>
    );
  } else {
    creationInfo = (
      <ButtonContainer>
        {renderSelectCreationButton()}
        <Button type="secondary" onClick={handleClickCreateCreation} data-testid="create-calibration-creation">
          {text.btnCreateACreation}
        </Button>
      </ButtonContainer>
    );
  }

  return (
    <li className="calibration-edit-creation-list__item" key={creationIndex} data-testid={testid}>
      <div className="calibration-edit-creation-list__item-label">{`Calibration ${creationIndex + 1}`}</div>
      {creationInfo}
    </li>
  );
}

SelectedCalibrationCreation.defaultProps = {
  creation: null,
  creationIndex: null,
};

const SelectedCalibrationCreationConnected = connect(
  (state: any, ownProps: any) => {
    return {
      creation: creationSelector.getSubmission(state, ownProps.selectedCreationId),
      user: state.user.authUser,
      activity: selectCurrentActivity(state),
      calibrationCreationOptions: selectCalibrationOptions(state),
    };
  },
  {
    setCalibrationCreationEditorVisibility,
  }
)(SelectedCalibrationCreation);

type CalibrationFormState = any;

class CalibrationFormComponent extends React.Component<{}, CalibrationFormState> {
  constructor(props: any) {
    super(props);
    this.state = {
      selectedCourse: null,
      selectedCalibrationActivity: null,
      selectedActivity: null,
      isLoadingCreations: false,
      isLoadingStudents: false,
      currentActivity: null,
      creationIndex: -1,
      showUpdateRubricNotice: false,
      isTriggerUpdate: false,
      originalRubric: null,
      isSelectingActivity: false,
    };
  }

  componentDidMount() {
    (this.props as any).getCalibrationCourses();
  }

  async componentDidUpdate(prevProps: {}, prevState: CalibrationFormState) {
    if ((this.props as any).values.rubric && prevState.originalRubric === null) {
      this.setState({ originalRubric: (this.props as any).values.rubric });
    }
    if ((this.props as any).values.rubric && prevState.originalRubric !== (this.props as any).values.rubric) {
      // if it is selecting different activity
      if (prevState.selectedActivity !== this.state.selectedActivity) {
        this.setState({
          isSelectingActivity: true,
          originalRubric: (this.props as any).values.rubric,
        });
      } else {
        if (prevState.isSelectingActivity) {
          this.setState({ isSelectingActivity: false });
        }
      }
    }
    if ((this.props as any).finalizedActivityList !== (prevProps as any).finalizedActivityList && !this.isEditing()) {
      await this.setActivityToEdit();
    }
    if (prevState.selectedCourse === null && (this.props as any).course) {
      this.setState({ selectedCourse: (this.props as any).course._id }, () =>
        (this.props as any).getCalibrationActivity({ courseId: (this.props as any).course._id })
      );
    }
    if (prevState.selectedCourse !== this.state.selectedCourse) {
      await this.getStudents(this.state.selectedCourse);
    }

    // Once the calibration creations are loaded
    // Load the students from the course that the calibration originated from, if this course is not the current course
    if (
      (prevProps as any).calibrationCreationOptions.length === 0 &&
      (this.props as any).calibrationCreationOptions.length > 0
    ) {
      if (this.isEditing()) {
        const originalCourseId = (this.props as any).calibrationCreationOptions[0].course;
        if (originalCourseId && originalCourseId !== (this.props as any).course._id) {
          await this.getStudents(originalCourseId);
        }
      }
    }
  }

  componentWillUnmount() {
    (this.props as any).setCalibrationCreationEditorVisibility(false);
  }

  getStudents = async (courseId: any) => {
    this.setState({ isLoadingStudents: true });
    await (this.props as any).getStudentsByCourse({ courseId });
    this.setState({ isLoadingStudents: false });
  };

  async setActivityToEdit() {
    const activity = localUtil.getFormActivity((this.props as any).values);
    if (activity) {
      this.setState({
        selectedActivity: activity,
        currentActivity: activity,
      });
      if (activity.isCalibrationActivity) {
        await this.queryCreations(activity.isCalibrationActivity);
      }
    }
  }

  isEditing() {
    return !!this.state.currentActivity;
  }

  async getCreationsOfActivity(activityId: any) {
    this.setState({ isLoadingCreations: true });
    await (this.props as any).getAssignmentSubmissions({ assignmentId: activityId });
    this.setState({ isLoadingCreations: false });
  }

  queryCreations(activityId: any) {
    return this.getCreationsOfActivity(activityId).then(() => this.setCalibrationCreations());
  }

  getCourseSelectionOptions() {
    const getLabel = (course: any) => {
      const isCurrentCourse = course._id === (this.props as any).course._id;
      const title = isCurrentCourse ? 'Current course' : formatPartialCourseBreadcrumb(course);
      return title;
    };
    let sortedCourses = [...(this.props as any).instructorCourseList];
    sortedCourses.sort((course1, course2) => {
      const title1 = course1.title.toLowerCase();
      const title2 = course2.title.toLowerCase();
      if (title1 > title2) {
        return 1;
      }
      if (title1 < title2) {
        return -1;
      }
      return 0;
    });
    const index = sortedCourses.findIndex((course) => {
      return course._id === (this.props as any).course._id;
    });
    if (index >= 0) {
      const item = sortedCourses.splice(index, 1);
      sortedCourses = [...item, ...sortedCourses];
    }
    return sortedCourses.map((course) => {
      return {
        label: getLabel(course),
        value: course._id,
        disabled: course.activityCount === 0,
        description: course.activityCount === 0 ? 'No activities' : '',
      };
    });
  }

  async handleSelectCalibrationActivity(e: any) {
    const activity = this.getCalibrationActivity(e.target.value);
    this.setState({
      selectedCalibrationActivity: e.target.value,
      selectedActivity: activity,
      originalRubric: activity.rubric,
    });
    await this.queryCreations(activity._id);
    // call onChange callback should execute after to fix a
    // case that form values not set before getting activity creations
    this.onChangeTargetActivity(activity);
  }

  onChangeTargetActivity(activity: any) {
    (this.props as any).form.mutators.setValue('isCalibrationActivity', activity._id);
    (this.props as any).form.mutators.setValue(OBJECTIVE, activity.objective);
    (this.props as any).form.mutators.setValue(INSTRUCTIONS, activity.instructions);
    (this.props as any).form.mutators.setValue(RUBRIC, activity.rubric);
    (this.props as any).form.mutators.setValue('calibrationActivity', activity);
  }

  getCalibrationActivity(activityId: any) {
    const activity = (this.props as any).finalizedActivityList.find((activity: any) => {
      return activity._id == activityId;
    });
    return activity;
  }

  getCourseName() {
    if (this.state.selectedCourse && (this.props as any).instructorCourseList) {
      if ((this.props as any).instructorCourseList.length === 0) {
        return null;
      }
      return (this.props as any).instructorCourseList.filter((course: any) => {
        return course._id === this.state.selectedCourse.toString();
      })[0]?._id;
    }
    return null;
  }

  setCalibrationCreations() {
    const activityId = this.state.currentActivity
      ? this.state.currentActivity.isCalibrationActivity
      : this.state.selectedCalibrationActivity;
    const calibrationCreations = Object.values((this.props as any).entities.user_assignments).filter((creation) => {
      // @ts-expect-error TS(2339) FIXME: Property 'assignment' does not exist on type 'unkn... Remove this comment to see the full error message
      return creation.assignment === activityId && !creationStatusUtil.wasCreationMissed(creation);
    });

    this.setState({ calibrationCreationOptions: calibrationCreations });
    (this.props as any).setCalibrationCreationOptions(calibrationCreations);
  }

  handleSelectCourse(e: any) {
    const { value } = e.target;
    this.setState({
      selectedCourse: value,
      calibrationCreationOptions: [],
      selectedActivity: null,
      selectedCalibrationActivity: null,
    });
    (this.props as any).setCalibrationCreationOptions([]);
    (this.props as any).getCalibrationActivity({ courseId: value });
  }

  handleUpdateRubricNotice = () => {
    if (!this.state.isTriggerUpdate) {
      this.setState({ isTriggerUpdate: true });
    } else {
      if (!this.state.isSelectingActivity) {
        this.setState({ showUpdateRubricNotice: true });
      }
    }
  };

  isRubricChanged = () => {
    if (this.state.isSelectingActivity) {
      return false;
    }
    if (this.state.originalRubric && (this.props as any).form.getState().values?.rubric) {
      return hasCriteriaOrLevelChanged(this.state.originalRubric, (this.props as any).form.getState().values?.rubric);
    }
    return false;
  };

  removeUpdateRubricNotice = () => {
    this.setState({
      isTriggerUpdate: false,
      showUpdateRubricNotice: false,
      originalRubric: null,
    });
  };

  clearCalibrationCreationMarks = () => {
    const calibrationCreations = (this.props as any).values.calibrationCreations.map((creation) => {
      if (creation) {
        creation.marks = [];
      }
      return creation;
    });
    (this.props as any).form.mutators.setValue('calibrationCreations', calibrationCreations);
  };

  canEditRubric = () => {
    if ((this.props as any).values.settings.isDuplicating) {
      return true;
    }
    const { activity } = this.props as any;
    const isValidStatus =
      !ActivityStatusUtil.isScheduled(activity) ||
      ActivityStatusUtil.isDraft({ assignment: activity }) ||
      ActivityStatusUtil.isCreate({ assignment: activity });

    return isValidStatus;
  };

  renderCreationMenu() {
    if (!this.shouldRenderDetailFields()) {
      return null;
    }

    return (
      <>
        <div ref={(this.props as any).innerRef}>
          <FormSection>
            <FormTitle label={localize({ message: 'Form.Calibration.CreationSelection.Title' })} size="xl" />
            <p>{localize({ message: 'Calibration.Form.DescriptiveText' })}</p>
            <FieldRFF required name="calibrationCreations" validate={validateCalibrationCreations}>
              {({ meta }) => {
                return (
                  <FormField error={meta.error && meta.touched && meta.error}>
                    {this.renderCalibrationSelections()}
                  </FormField>
                );
              }}
            </FieldRFF>
          </FormSection>
        </div>
      </>
    );
  }

  shouldRenderDetailFields() {
    return !utils.isSourcingPastActivity((this.props as any).values) || this.state.selectedActivity;
  }

  onSelectRubric(rubric: any) {
    (this.props as any).form.mutators.setValue(RUBRIC, rubric);
  }

  renderTitleAndDetailFields() {
    return (
      <React.Fragment>
        <FormTitle label={localize({ message: 'Form.Calibration.ActivityDetails.Title' })} size="xl" />
        <TitleField />
        <ObjectiveField />
        <InstructionsField />
        <FieldRFF name="files">
          {({ input }) => {
            return (
              <FormField>
                <FormFieldLabel label={localize({ message: 'Form.Calibration.Resources.Label' })} />
                <AttachmentManager onFileChange={input.onChange} fileList={input.value} />
              </FormField>
            );
          }}
        </FieldRFF>
        <FormSection>
          <FormTitle label={RUBRIC_LABEL} size="xl" />
          <RubricPreview
            rubric={(this.props as any).values[RUBRIC]}
            canEditRubric={this.canEditRubric()}
            isNumericGrading={(this.props as any).values.isNumericGrading}
            onSelectRubric={this.onSelectRubric.bind(this)}
            clearCalibrationCreationMarks={this.clearCalibrationCreationMarks}
            handleUpdateRubricNotice={this.handleUpdateRubricNotice}
            canViewMode={(this.props as any).canViewMode}
            form={(this.props as any).form}
          />
          {(this.state.showUpdateRubricNotice || this.isRubricChanged()) && (
            <InlineInformation type="information" className="calibration-rubric-update-notice">
              {localize({ message: 'Rubric.Edit.Save.Notice' })}
            </InlineInformation>
          )}
        </FormSection>
      </React.Fragment>
    );
  }

  renderCurrentSelectCourseValue(value: any) {
    if ((this.props as any).course._id === value) {
      return 'Current Course';
    }
    if ((this.props as any).instructorCourseList) {
      const selectedCourse = (this.props as any).instructorCourseList.find((course: any) => course._id === value);
      if (selectedCourse) {
        return formatPartialCourseBreadcrumb(selectedCourse);
      }
    }
    return null;
  }

  renderCourseSelector() {
    if (this.isEditing()) {
      return null;
    }
    return (
      <FormField>
        <Select
          label={localize({ message: 'Form.Calibration.CourseSelector.Label' })}
          value={this.getCourseName()}
          onChange={(e: any) => {
            return this.handleSelectCourse(e);
          }}
          options={this.getCourseSelectionOptions()}
          placeholder={localize({ message: 'Form.Calibration.CourseSelector.Placeholder' })}
          testid="calibration_select-course"
          renderValue={(value: any) => {
            return this.renderCurrentSelectCourseValue(value);
          }}
        />
      </FormField>
    );
  }

  renderCalibrationBanner = () => {
    const description = (
      <>
        <TranslatedText i18nKey="Form.Calibration.Banner.Description" />
      </>
    );
    const COPY = {
      image: <Badge />,
      title: localize({ message: 'Form.Calibration.Banner.Title' }),
      description,
      link: {
        target: 'https://help.kritik.io/en/articles/6387297-calibration-activities',
        type: 'url',
      },
    };
    return <Banner title={COPY.title} description={COPY.description} image={COPY.image} link={COPY.link} />;
  };

  handleSelectCreation = (idx: any) => {
    (this.props as any).showCreationSelector(idx);
  };

  handleRemoveCreation = (idx: any) => {
    const scoreList = (this.props as any).values.calibrationCreations;
    scoreList.splice(idx, 1, null);
    (this.props as any).form.mutators.setValue('calibrationCreations', scoreList);
  };

  setCreationIndex = (i: any) => {
    this.setState({
      creationIndex: i,
    });
  };

  renderCalibrationSelections() {
    if (this.state.isLoadingCreations || this.state.isLoadingStudents) {
      return null;
    }
    const activity = localUtil.getFormActivity((this.props as any).values);
    if (
      this.isEditing() &&
      (this.props as any).calibrationCreationOptions.length === 0 &&
      activity?.isCalibrationActivity
    ) {
      return (
        <InlineInformation type="warning">
          <TranslatedText i18nKey="Form.Calibration.NoCreations.Warning" />
        </InlineInformation>
      );
    }
    const renderCreations = () => {
      return (this.props as any).values.calibrationCreations.map((creation: any, creationIndex: any) => {
        const creationId = creation ? creation._id : '';
        return (
          <SelectedCalibrationCreationConnected
            key={`${creationId || creationIndex}-${Math.floor(Math.random() * 1000) + 1}`}
            selectedCreationId={creationId}
            creationIndex={creationIndex}
            selectedActivity={this.state.selectedActivity}
            handleRemoveCreation={this.handleRemoveCreation}
            handleSelectCreation={this.handleSelectCreation}
            setCreationIndex={this.setCreationIndex}
            testid={`selected-calibration-creation-${creationIndex}`}
            creationFormValue={creation}
            isDuplicating={(this.props as any).values.settings.isDuplicating}
          />
        );
      });
    };
    return <ul className="calibration-edit-creation-list"> {renderCreations()} </ul>;
  }

  onCloseCreationEditor = () => {
    (this.props as any).resetAsync(CREATE_CALIBRATION_CREATION);
    (this.props as any).resetAsync(UPDATE_CALIBRATION_CREATION);
  };

  renderPastActivityOptions = () => {
    if (utils.isSourcingPastActivity((this.props as any).values)) {
      return (
        <>
          {this.renderCourseSelector()}
          <ActivitySelector
            onChange={async (e: any) => {
              return await this.handleSelectCalibrationActivity(e);
            }}
            course={this.state.selectedCourse}
            selectedCalibrationActivity={this.state.selectedCalibrationActivity}
            finalizedActivityList={(this.props as any).finalizedActivityList}
          />
        </>
      );
    }
    return null;
  };

  renderTitle = () => {
    return (
      <h3>
        <TranslatedText i18nKey="Form.Calibration.Title" />
      </h3>
    );
  };

  renderCalibrationSourceOption() {
    if (this.isEditing()) {
      return null;
    }
    return (
      <CalibrationSourceOption
        formState={(this.props as any).form.getState()}
        mutators={(this.props as any).form.mutators}
        settings={(this.props as any).values.settings}
        onChange={(this.props as any).changeCalibrationSource}
        removeUpdateRubricNotice={this.removeUpdateRubricNotice}
      />
    );
  }

  renderForm = () => {
    if (!this.shouldRenderDetailFields()) {
      return null;
    }
    return (
      <FormContainer>
        {this.renderTitleAndDetailFields()}
        {this.renderCreationMenu()}
      </FormContainer>
    );
  };

  render() {
    return (
      <>
        {this.renderTitle()}
        <FormField>{this.renderCalibrationBanner()}</FormField>
        {this.renderCalibrationSourceOption()}
        {this.renderPastActivityOptions()}
        {this.renderForm()}
        {this.shouldRenderDetailFields() && (
          <FullScreenModal
            open={(this.props as any).calibrationCreationEditorVisibility}
            onExited={this.onCloseCreationEditor}
          >
            <PageContainer>
              <PageHeader title={<TranslatedText i18nKey="Activity.Create.Calibration.PageHeader" />} />
              <CalibrationCreationEditor
                rubric={(this.props as any).values.rubric}
                activity={this.state.selectedActivity}
                creationIndex={this.state.creationIndex}
                form={(this.props as any).form}
              />
            </PageContainer>
          </FullScreenModal>
        )}
      </>
    );
  }
}

const Calibration = (props: any) => {
  return <FormSpy component={CalibrationFormComponent} {...props} />;
};

const mapStateToProps = (state: any) => {
  return {
    finalizedActivityList: getFinalizedActivitiesWithoutCalibrations(state),
    instructorCourseList: selectCalibrationActivityCourseList(state),
    entities: state.entities,
    calibrationCreationEditorVisibility: selectCalibrationCreationEditorVisibility(state),
    calibrationCreationOptions: selectCalibrationOptions(state),
    course: selectCurrentCourse(state),
    activity: selectCurrentActivity(state),
  };
};

export const CalibrationForm = connect(mapStateToProps, {
  getAssignmentSubmissions,
  showCreationSelector,
  setCalibrationCreationOptions,
  getCalibrationCourses,
  getCalibrationActivity,
  setCalibrationCreationEditorVisibility,
  getStudentsByCourse,
  resetAsync,
})(Calibration);
