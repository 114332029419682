import { PRESENT_STATUS, StageValues, Stages } from '@kritik/constants/stage';
import type { Activity, AssignmentStatus } from '@kritik/types.generated';
import * as ActivityUtils from '@kritik/utils/activity';
import moment from 'moment-timezone';

export function mapStatusToDisplayedStatus(status: StageValues) {
  if (status === Stages.PROCESSING3) {
    return 'Grading';
  }
  if (status === Stages.PROCESSING1) {
    return 'Grace Period';
  }
  return status;
}

const getActivityStatusesTyped = (activity: Activity) => (activity?.statuses || []) as AssignmentStatus[];

export function getDraftStage(activity: Activity) {
  return getActivityStatusesTyped(activity).find((status) => status.name === Stages.DRAFT);
}

export function getCreationStage(activity: Activity) {
  return getActivityStatusesTyped(activity).find((status) => status.name === Stages.CREATE);
}

export function getCreationGracePeriodStage(activity: Activity) {
  return getActivityStatusesTyped(activity).find((status) => status.name === Stages.PROCESSING1);
}

export function getEvaluationStage(activity: Activity) {
  return getActivityStatusesTyped(activity).find((status) => status.name === Stages.EVALUATE);
}

export function getEvaluationGracePeriodStage(activity: Activity) {
  return getActivityStatusesTyped(activity).find((status) => status.name === Stages.PROCESSING2);
}

export function getProcessing2Stage(activity: Activity) {
  return getActivityStatusesTyped(activity).find((status) => status.name === Stages.PROCESSING2);
}

export function getFeedbackStage(activity: Activity) {
  return getActivityStatusesTyped(activity).find((status) => status.name === Stages.FEEDBACK);
}

export function getGradingStage(activity: Activity) {
  return getActivityStatusesTyped(activity).find((status) => status.name === Stages.PROCESSING3);
}

export function getFinalizedStage(activity: Activity) {
  return getActivityStatusesTyped(activity).find((status) => status.name === Stages.FINALIZED);
}

export function _getNextStatus({ statusName, activity }: { statusName: StageValues; activity: Activity }) {
  switch (statusName) {
    case Stages.DRAFT:
      return getCreationStage(activity);
    case Stages.CREATE:
      return getCreationGracePeriodStage(activity);
    case Stages.PROCESSING1:
      return getEvaluationStage(activity);
    case Stages.EVALUATE:
      return getProcessing2Stage(activity);
    case Stages.PROCESSING2:
      return getFeedbackStage(activity);
    case Stages.FEEDBACK:
      return getGradingStage(activity);
    case Stages.PROCESSING3:
      return getFinalizedStage(activity);
    default:
      return null;
  }
}

export function isProcessing({ assignment }: { assignment: Activity }) {
  if (!assignment) {
    return false;
  }
  return getCreationGracePeriodStage(assignment)?.active || getProcessing2Stage(assignment)?.active;
}

export function isProcessing1({ assignment }: { assignment: Activity }) {
  if (!assignment) {
    return false;
  }
  return getCreationGracePeriodStage(assignment)?.active;
}

export function isProcessing2({ assignment }: { assignment: Activity }) {
  if (!assignment) {
    return false;
  }
  return getProcessing2Stage(assignment)?.active;
}

export function isDraft({ assignment }: { assignment: Activity }) {
  if (!assignment) {
    return false;
  }
  return getDraftStage(assignment)?.active;
}

export function isCreate({ assignment }: { assignment: Activity }) {
  if (!assignment) {
    return false;
  }
  return getCreationStage(assignment)?.active;
}

export function isEvaluate({ assignment }: { assignment: Activity }) {
  if (!assignment) {
    return false;
  }
  return getEvaluationStage(assignment)?.active;
}

export function isGrading({ assignment }: { assignment: Activity }) {
  if (!assignment) {
    return false;
  }
  return getGradingStage(assignment)?.active;
}

export function isFeedback({ assignment }: { assignment: Activity }) {
  if (!assignment) {
    return false;
  }
  return getFeedbackStage(assignment)?.active;
}

export function isFinalized({ assignment }: { assignment: Activity }) {
  if (!assignment) {
    return false;
  }
  return getFinalizedStage(assignment)?.active;
}

export function isCreateOrLater(assignment: Activity) {
  return (
    isCreate({ assignment }) ||
    isInCreationGracePeriod(assignment) ||
    isEvaluate({ assignment }) ||
    isProcessing2({ assignment }) ||
    isFeedback({ assignment }) ||
    isGrading({ assignment }) ||
    isFinalized({ assignment })
  );
}

export function isEvaluateOrLater(assignment: Activity) {
  return (
    isEvaluate({ assignment }) ||
    isProcessing2({ assignment }) ||
    isFeedback({ assignment }) ||
    isGrading({ assignment }) ||
    isFinalized({ assignment })
  );
}

export function isFeedbackOrLater({ assignment }: { assignment: Activity }) {
  if (!assignment) {
    return false;
  }
  return isFeedback({ assignment }) || isGrading({ assignment }) || isFinalized({ assignment });
}

export function isGradingOrLater(assignment: Activity) {
  if (!assignment) {
    return false;
  }
  return isGrading({ assignment }) || isFinalized({ assignment });
}

export function isScheduled(assignment: Activity) {
  if (!assignment || !assignment.statuses) {
    return false;
  }
  const status = ActivityUtils.isCalibrationActivity(assignment)
    ? getEvaluationStage(assignment)
    : getCreationStage(assignment);
  return Boolean(status.startDate);
}

export function getActiveStatus(assignment: Activity) {
  return getActivityStatusesTyped(assignment).find((status) => {
    return status.active;
  });
}

export function getNextStatus(assignment: Activity) {
  const currentStatus = getActivityStatusesTyped(assignment).find((status) => {
    return status.active;
  });
  return ActivityUtils.isCalibrationActivity(assignment)
    ? getEvaluationStage(assignment)
    : _getNextStatus({ statusName: currentStatus.name, activity: assignment });
}

export function isActionableStatus(assignment: Activity) {
  if (isDraft({ assignment }) || isProcessing({ assignment })) {
    return false;
  }
  return true;
}

export function getNextActionableStatus(assignment: Activity) {
  if (isActionableStatus(assignment)) {
    return getActiveStatus(assignment);
  }
  return getNextStatus(assignment);
}

export function getNextDate(assignment: Activity) {
  const currentStatus = getActiveStatus(assignment);
  const nextActionableStatus = getNextActionableStatus(assignment);

  if (currentStatus._id === nextActionableStatus._id) {
    return nextActionableStatus.endDate;
  }

  return nextActionableStatus.startDate;
}

export function getStatusEndDate(status: AssignmentStatus) {
  return status.endDate;
}

export function getIsDifferentTimeZone(timeZone: string) {
  const now = moment.utc().valueOf();
  const clientOffset = moment.tz.zone(moment.tz.guess(true)).utcOffset(now);
  const insOffset = moment.tz.zone(timeZone).utcOffset(now);
  return clientOffset !== insOffset;
}

export function isPastDeadline(status: AssignmentStatus) {
  return Date.now() > new Date(status.endDate).getTime();
}

export function isPastCreationDeadline(assignment: Activity) {
  const status = getCreationStage(assignment);
  return isPastDeadline(status);
}

export function isPastGracePeriodDeadline(assignment: Activity) {
  let status = getCreationGracePeriodStage(assignment);
  if (!hasGracePeriod(assignment)) {
    status = getCreationStage(assignment);
  }
  return isPastDeadline(status);
}

export function isPastEvaluationDeadline(assignment: Activity) {
  const status = getEvaluationStage(assignment);
  return isPastDeadline(status);
}

export function isPastFeedbackDeadline(assignment: Activity) {
  const status = getFeedbackStage(assignment);
  return isPastDeadline(status);
}

export function isInCreationGracePeriod(assignment: Activity) {
  const status = getCreationGracePeriodStage(assignment);
  if (!status) {
    return false;
  }
  if (status.startDate && status.endDate) {
    return status.active;
  }
  return false;
}

export function isInEvaluationGracePeriod(assignment: Activity) {
  const status = getEvaluationGracePeriodStage(assignment);
  if (!status) {
    return false;
  }
  if (status.startDate && status.endDate) {
    return status.active;
  }
  return false;
}

export function canSubmitCreation(assignment: Activity) {
  return isInCreationGracePeriod(assignment) || isCreate({ assignment });
}

export function hasGracePeriod(assignment: Activity) {
  const gracePeriodStatus = getCreationGracePeriodStage(assignment);
  return !!(gracePeriodStatus && gracePeriodStatus.startDate && gracePeriodStatus.endDate);
}

export function hasEvaluationGracePeriod(assignment: Activity) {
  const gracePeriodStatus = getEvaluationGracePeriodStage(assignment);
  return !!(gracePeriodStatus && gracePeriodStatus.startDate && gracePeriodStatus.endDate);
}

export function getGracePeriodLengthInMinutes(gracePeriodStatus: AssignmentStatus) {
  return moment(gracePeriodStatus.endDate).diff(gracePeriodStatus.startDate, 'minutes');
}

export function getActionableStatusList(activity: Activity, isStudent: boolean = false) {
  const statusList = [];
  if (!activity) {
    return statusList;
  }
  if (ActivityUtils.isPresentationActivity(activity) && isStudent) {
    return getActionableStatusListForPresentationActivity(activity);
  }
  if (ActivityUtils.isCalibrationActivity(activity)) {
    statusList.push(getEvaluationStage(activity));
  } else {
    statusList.push(getCreationStage(activity));
    statusList.push(getEvaluationStage(activity));
    statusList.push(getFeedbackStage(activity));
    statusList.push(getGradingStage(activity));
  }
  return statusList;
}

export function getActionableStatusListForPresentationActivity(activity: Activity) {
  const submissionIsOptionalOrRequired = activity.fileExtensionsAllowed !== null;
  const statusList: PresentationActivityStage[] = [];

  const creationStage = getCreationStage(activity) as PresentationActivityStage;
  const evaluationStage = getEvaluationStage(activity) as PresentationActivityStage;
  const feedbackStage = getFeedbackStage(activity) as PresentationActivityStage;

  if (submissionIsOptionalOrRequired) {
    if (creationStage.isParticipating) {
      statusList.push(creationStage);
      if (!evaluationStage.isParticipating) {
        statusList.push({ ...evaluationStage, name: 'Present' });
      }
    }

    if (evaluationStage.isParticipating) {
      if (creationStage.isParticipating) {
        statusList.push({ ...evaluationStage, name: 'Present' });
      } else {
        statusList.push(evaluationStage);
      }
    }
  } else {
    if (creationStage.isParticipating) {
      statusList.push({ ...evaluationStage, name: 'Present' });
    } else {
      if (evaluationStage.isParticipating) {
        statusList.push(evaluationStage);
      }
    }
  }

  if (feedbackStage.isParticipating) {
    statusList.push(feedbackStage);
  }
  // All students participate to Grading Stage by default
  statusList.push(getGradingStage(activity) as PresentationActivityStage);
  return statusList;
}

export function isActiveStatus(status: AssignmentStatus) {
  return status.active;
}

export function checkIfBeforeStatus(status1: string, status2: string) {
  switch (status1) {
    case Stages.DRAFT:
      return (
        [
          Stages.CREATE,
          Stages.PROCESSING1,
          Stages.EVALUATE,
          Stages.PROCESSING2,
          Stages.FEEDBACK,
          Stages.PROCESSING3,
        ] as string[]
      ).includes(status2);
    case Stages.CREATE:
      return (
        [Stages.PROCESSING1, Stages.EVALUATE, Stages.PROCESSING2, Stages.FEEDBACK, Stages.PROCESSING3] as string[]
      ).includes(status2);
    case Stages.PROCESSING1:
      return ([Stages.EVALUATE, Stages.PROCESSING2, Stages.FEEDBACK, Stages.PROCESSING3] as string[]).includes(status2);
    case Stages.EVALUATE:
    case PRESENT_STATUS:
      return ([Stages.PROCESSING2, Stages.FEEDBACK, Stages.PROCESSING3] as string[]).includes(status2);
    case Stages.PROCESSING2:
      return ([Stages.FEEDBACK, Stages.PROCESSING3] as string[]).includes(status2);
    case Stages.FEEDBACK:
      return ([Stages.PROCESSING3] as string[]).includes(status2);
    default:
      return false;
  }
}

export function isPastStatus(currentStatus: AssignmentStatus, activity: Activity) {
  if (isActiveStatus(currentStatus)) {
    return false;
  }
  const activeStatus = getActiveStatus(activity);
  if (activeStatus.name === currentStatus.name) {
    return false;
  }
  return checkIfBeforeStatus(currentStatus.name, activeStatus.name);
}

export function isFutureStatus(currentStatus: AssignmentStatus, activity: Activity) {
  if (isActiveStatus(currentStatus)) {
    return false;
  }
  const activeStatus = getActiveStatus(activity);
  if (activeStatus.name === currentStatus.name) {
    return false;
  }
  return !checkIfBeforeStatus(currentStatus.name, activeStatus.name);
}

export function isStatusStartingNext(status: AssignmentStatus, activity: Activity) {
  if (isActiveStatus(status)) {
    return false;
  }
  const nextStatus = getNextActionableStatus(activity);
  if (isScheduled(activity) && nextStatus._id === status._id) {
    return true;
  }
  return false;
}

export function getEndOfCreationPeriod(activity: Activity) {
  let status = getCreationGracePeriodStage(activity);
  if (!hasGracePeriod(activity)) {
    status = getCreationStage(activity);
  }
  return getStatusEndDate(status);
}

export function getEndOfEvaluationPeriod(activity: Activity) {
  let status = getEvaluationStage(activity);
  if (hasEvaluationGracePeriod(activity)) {
    status = getEvaluationGracePeriodStage(activity);
  }
  return getStatusEndDate(status);
}

export function getActiveStatusName(activity: Activity) {
  const name = getActiveStatus(activity)?.name || '-';
  if (name === Stages.PROCESSING1) {
    return 'Grace Period';
  }
  // return previous stage name which in this case is Evaluate
  // we do not want to show Processing 2 in gradebook
  if (name === Stages.PROCESSING2) {
    return 'Evaluate';
  }
  if (name === Stages.PROCESSING3) {
    return 'Grading';
  }
  return name;
}

export function getNumberOfGroupsParticipatingInCreateStage(activity: Activity) {
  return activity.participationSettings.creatorGroups.length;
}

export function getNumberOfStudentsParticipatingInStage(stage: AssignmentStatus) {
  const participants = stage.students.filter((student) => student.isParticipating);
  return participants.length;
}

export function studentParticipatesInStageFromInstructorView({
  stage,
  studentId,
}: {
  stage: AssignmentStatus;
  studentId: string;
}): boolean {
  return (stage.students || []).some(
    (_student) => _student.isParticipating && _student.student.toString() === studentId.toString()
  );
}

export function studentParticipatesInActivityFromStudentView({ activity }: { activity: Activity }): boolean {
  return activity.statuses.some((stage) => stage.isParticipating);
}

export const activeStages = {
  DRAFT: 'Draft',
  CREATE: 'Create',
  CREATE_GRACE_PERIOD: 'CreateGracePeriod',
  EVALUATE: 'Evaluate',
  EVALUATE_GRACE_PERIOD: 'EvaluateGracePeriod',
  FEEDBACK: 'Feedback',
  GRADING: 'Grading',
  FINALIZED: 'Finalized',
} as const;

export function translateStageName({ stageName, activity }) {
  switch (stageName) {
    case activeStages.CREATE_GRACE_PERIOD:
      return 'Create Grace Period';
    case activeStages.EVALUATE_GRACE_PERIOD:
      return 'Evaluate Grace Period';
    case activeStages.FINALIZED:
      return 'Complete';

    case activeStages.EVALUATE:
      if (ActivityUtils.isPresentationActivity(activity)) {
        return 'Present/Evaluate';
      }
    default:
      return stageName;
  }
}
