import { localize } from 'locales';
import { enqueueSnackbar } from 'notistack';
import ActivityService from 'services/activity';
import PlagiarismService from 'services/plagiarism';

export async function initiateAiContentCheck(activityId: string) {
  try {
    await PlagiarismService().exportContentReport({ activityId });
    enqueueSnackbar(
      localize({
        message: 'Plagiarism.AI.Activity.ContentCheck.Success.Message',
      }),
      {
        title: localize({
          message: 'Plagiarism.AI.Activity.ContentCheck.Success.Title',
        }),
        variant: 'success',
      }
    );
  } catch (error) {
    enqueueSnackbar(
      localize({
        message: 'Plagiarism.AI.Activity.ContentCheck.Error.Message',
      }),
      {
        title: localize({
          message: 'Plagiarism.AI.Activity.ContentCheck.Error.Title',
        }),
        variant: 'error',
      }
    );
  }
}

export async function initiateCreationContentExport(activityId: string) {
  try {
    await ActivityService().exportCreations({ activityId });
    enqueueSnackbar(
      localize({
        message: 'CreationExport.Activity.Success.Message',
      }),
      {
        title: localize({
          message: 'CreationExport.Activity.Success.Title',
        }),
        variant: 'success',
      }
    );
  } catch (error) {
    enqueueSnackbar(
      localize({
        message: 'CreationExport.Activity.Error.Message',
      }),
      {
        title: localize({
          message: 'CreationExport.Activity.Error.Title',
        }),
        variant: 'error',
      }
    );
  }
}
