import { Stages } from '@kritik/constants/stage';
import { isGroupAssignment, isGroupPresentationActivity } from '@kritik/utils/activity';
import * as CreationUtils from '@kritik/utils/creation/general';
import { calcProgress } from '@kritik/utils/creation/general';
import { getNumberOfGroupsParticipatingInCreateStage } from '@kritik/utils/stage';

const getCreationProgress = (activityStats: any, activity: any) => {
  let progress = 0;

  if (isGroupAssignment(activity)) {
    const { groups, creationsSubmitted } = activityStats;
    progress = calcProgress(creationsSubmitted, groups.length);
  } else if (isGroupPresentationActivity(activity)) {
    const { creationsSubmitted } = activityStats;
    const groupCount = getNumberOfGroupsParticipatingInCreateStage(activity);
    progress = CreationUtils.calcProgress(creationsSubmitted, groupCount);
  } else {
    const { creationsExpected, creationsSubmitted } = activityStats;
    progress = calcProgress(creationsSubmitted, creationsExpected);
  }
  return progress;
};

const getEvaluateProgress = (activityStats: any) => {
  const { numEvalsCompleted, numEvalsExpected } = activityStats;
  return calcProgress(numEvalsCompleted, numEvalsExpected);
};

const getFeedbackProgress = (activityStats: any) => {
  const { numFOFCompleted, numFOFExpected } = activityStats;
  return calcProgress(numFOFCompleted, numFOFExpected);
};

export function getProgress({ activityStats, activity, statusName }: any) {
  switch (statusName) {
    case Stages.CREATE: {
      return getCreationProgress(activityStats, activity);
    }
    case Stages.EVALUATE: {
      return getEvaluateProgress(activityStats);
    }
    case Stages.FEEDBACK: {
      return getFeedbackProgress(activityStats);
    }
    default:
      return 0;
  }
}
