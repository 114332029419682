import * as assignmentUtil from '@kritik/utils/activity';
import { isFeedback } from '@kritik/utils/stage';
import classNames from 'classnames';
import SpotlightList from 'components/Spotlight/List';
import MarginVertical from 'components/Spotlight/View/MarginVertical';
import SpotlightProvider, { SpotlightContext } from 'components/Spotlight/context';
import { CollapsibleArea } from 'components/layout/CollapsibleArea';
import CollapsibleComponent from 'components/layout/CollapsibleComponent';
import SideBar from 'containers/assignments/overview/SideBar';
import { localize } from 'locales';
import { Component } from 'react';
import { connect } from 'react-redux';
import { getAssignment } from 'selectors/activity';
import { withUserRole } from 'utils/withUserRole';
import ActivityBody from './ActivityBody';
import AssignmentEvaluation from './Grade';
import AssignmentGroup from './Group';
import AssignmentSchedule from './Schedule';
import AssignmentStatusPanel from './StatusPanel';
import StudentGradeView from './StudentGradeView';

const mapStateToProps = (state: any) => {
  return {
    assignment: getAssignment(state, state.selected.assignmentId),
    user: state.user,
  };
};

class AssignmentOverview extends Component {
  static defaultProps = {
    showSchedule: true,
  };

  renderGroupDetails() {
    if (
      assignmentUtil.isGroupAssignment((this.props as any).assignment) ||
      assignmentUtil.isWithinGroupActivity((this.props as any).assignment) ||
      assignmentUtil.isGroupPresentationActivity((this.props as any).assignment)
    ) {
      return <AssignmentGroup />;
    }
  }

  renderSideBar() {
    const items = [<AssignmentSchedule />, this.renderGroupDetails()];

    return <CollapsibleComponent header={<AssignmentStatusPanel />} body={<SideBar items={items} />} />;
  }

  renderSpotlightList() {
    const { userRole } = this.props as any;
    const { isInstructorInCourse } = userRole;
    return (
      <SpotlightProvider activityId={(this.props as any).assignment._id}>
        <SpotlightContext.Consumer>
          {(spotlights) => {
            if (!spotlights || !(spotlights as any).length) {
              return null;
            }
            return (
              <CollapsibleArea
                title={localize({ message: 'ActivityOverview.SpotlightedContent' })}
                defaultOpen={isInstructorInCourse && spotlights.length > 0}
              >
                <SpotlightList spotlights={spotlights} hideActivityLink />
              </CollapsibleArea>
            );
          }}
        </SpotlightContext.Consumer>
      </SpotlightProvider>
    );
  }

  gradeView() {
    // @ts-expect-error TS(2339) FIXME: Property 'assignment' does not exist on type 'Read... Remove this comment to see the full error message
    const { assignment, userRole } = this.props;
    const { isStudentInCourse } = userRole;
    if (isFeedback({ assignment }) && isStudentInCourse) {
      return <StudentGradeView activity={assignment} rubric={assignment.rubric} />;
    }
    return null;
  }

  render() {
    const assignmentOverviewClasses = classNames('activity-overview', {
      'activity-overview--full-width': !(this.props as any).showSchedule,
    });

    return (
      <div className={assignmentOverviewClasses}>
        <div className="activity-body">
          {this.gradeView()}
          <MarginVertical size="md" />
          <ActivityBody activity={(this.props as any).assignment} />
          <AssignmentEvaluation activity={(this.props as any).assignment} user={(this.props as any).user.authUser} />
          {this.renderSpotlightList()}
          <MarginVertical size="md" />
          {(this.props as any).children}
        </div>
        {(this.props as any).showSchedule ? this.renderSideBar() : null}
      </div>
    );
  }
}

export default withUserRole(connect(mapStateToProps, {})(AssignmentOverview));
